import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ListInterview from './ListInterview';
import { mobileThresholdPixels, contentWidth, margins }
  from '../../components/Home/v2/StyledComponents';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${margins.l} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 0px ${margins.m} 0px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  flex-wrap: wrap;
  width: ${contentWidth}px;
`;

const List = ({ interviews }) => (
  <Container>
    <Content>
      {interviews.map((interview, index) =>
        <ListInterview key={interview.title} interview={interview} right={!(index % 2)} />,
      )}
    </Content>
  </Container>
);

List.propTypes = {
  interviews: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default List;
